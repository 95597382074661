@import url(https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap);
* {
	box-sizing: border-box;
}

body {
	background: rgb(24, 0, 59) url(/static/media/sunset.e818b055.jpg);
	background-size: 110%;
	color: white;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: xx-large;
	overflow: hidden;
	margin: 0;
}

.container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 300px;
	height: 300px;
	margin: auto;
	position: relative;
	transform: scale(1);
}

#text {
	color: rgb(255, 218, 149);
	font-family: "Julius Sans One", sans-serif;
}

.gradientCircle {
	background: conic-gradient(
		rgb(224, 107, 83) 0%,
		rgb(236, 122, 65) 38%,
		rgb(233, 255, 160) 40%,
		rgb(233, 255, 160) 60%,
		rgb(232, 61, 61) 62%,
		rgb(224, 107, 83) 100%
	);
	border-radius: 50%;
	height: 320px;
	width: 320px;
	position: absolute;
	top: -10px;
	left: -10px;
	z-index: -2;
	box-shadow: 0px 0px 10px rgb(0, 1, 45);
}

.circle {
	background-color: rgb(0, 1, 45);
	border-radius: 50%;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.pointerContainer {
	position: absolute;
	top: -50px;
	left: 140px;
	width: 20px;
	height: 200px;
	-webkit-animation: rotate 7.5s linear forwards infinite;
	        animation: rotate 7.5s linear forwards infinite;
	transform-origin: bottom center;
}

.pointer {
	background-color: rgb(255, 255, 255);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: block;
	box-shadow: 0px 0px 10px;
}

.container.grow {
	-webkit-animation: grow 3s ease-in-out forwards;
	        animation: grow 3s ease-in-out forwards;
}

.container.shrink {
	-webkit-animation: shrink 3s ease-in-out forwards;
	        animation: shrink 3s ease-in-out forwards;
}

@-webkit-keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes grow {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(1.5);
	}
}

@keyframes grow {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(1.5);
	}
}

@-webkit-keyframes shrink {
	from {
		transform: scale(1.5);
	}
	to {
		transform: scale(1);
	}
}

@keyframes shrink {
	from {
		transform: scale(1.5);
	}
	to {
		transform: scale(1);
	}
}

